Listen to <b>{{ chapter['chapter_name'] }} set to music!</b>

<div>
  <button><img src="/assets/icons/ui/chevron_left-24px.svg" class="icon"/></button>
  <button><img src="/assets/icons/ui/play_arrow-24px.svg" class="icon play"/></button>
  <button><img src="/assets/icons/ui/chevron_right-24px.svg" class="icon"/></button>
</div>

<div id="other-chapters" *ngIf="otherChapters">
  Other {{ type }} chapters:
  <ng-container *ngFor="let otherChapter of otherChapters | keyvalue: originalOrder; last as isLast">
     <a *ngIf="otherChapter.value != ''" href="{{ otherChapter.value }}" (click)="$event.preventDefault(); navigate(otherChapter.value)">
       {{ otherChapter.key }}
     </a>
    <ng-container *ngIf="otherChapter.value == ''">{{ otherChapter.key }}</ng-container>
    <ng-container *ngIf="!isLast">, </ng-container>
  </ng-container>
</div>
