import { Injectable } from '@angular/core';
import { MediaService } from './media.service';

@Injectable({
  providedIn: 'root',
})
export class TriggerService {
  userIsTyping = false;
  bodyElement: any;

  controlKeyDown = false;
  altKeyDown = false;

  scrollContainer: any = window;
  scrollOffset: any = 'scrollY';

  constructor(private media: MediaService) {}

  watch() {
    this.bodyElement = document.querySelector('body');
    window.addEventListener('resize', this.onResize.bind(this));
    window.addEventListener(
      'orientationchange',
      this.onOrientationChange.bind(this)
    );
    this.onResize();
    this.onOrientationChange();
  }

  onResize() {
    /* Hero banner resizer */
    let headerSize = 2.6;
    let headerSizeUnit = 'vw';
    if (window.innerHeight < window.innerWidth) {
      if (window.innerHeight * 2 < window.innerWidth) {
        headerSize = 4;
        headerSizeUnit = 'vh';
      } else {
        headerSize = (window.innerHeight / window.innerWidth) * headerSize;
        if (headerSize < 2) {
          headerSize = 1.75;
        }
      }
    }
    this.bodyElement.style.setProperty(
      '--heroFontSize',
      headerSize + headerSizeUnit
    );
  }

  onOrientationChange() {
    this.bodyElement.style.setProperty(
      '--windowInnerHeight',
      window.innerHeight + 'px'
    );
  }

  keyDown(event) {
    switch (event.key) {
      case 'Alt':
        this.altKeyDown = true;
        break;
      case 'Control':
        this.controlKeyDown = true;
        break;
      case ' ':
        event.preventDefault();
        this.media.playPause();
        break;
      case 'ArrowLeft':
        if (!this.altKeyDown) {
          event.preventDefault();
          if (this.controlKeyDown) {
            this.media.previous();
          } else {
            this.media.rewind();
          }
        }
        break;
      case 'ArrowRight':
        if (!this.altKeyDown) {
          event.preventDefault();
          if (this.controlKeyDown) {
            this.media.next();
          } else {
            this.media.fastForward();
          }
        }
        break;
    }
  }

  keyUp(event) {
    switch (event.key) {
      case 'Alt':
        this.altKeyDown = false;
        break;
      case 'Control':
        this.controlKeyDown = false;
        break;
    }
  }

  // mousewheel(positive: boolean) {
  //   if (positive) {
  //     this.media.currentPlayer.audioElement.currentTime -= 2;
  //   } else {
  //     this.media.currentPlayer.audioElement.currentTime += 2;
  //   }
  // }
}
