<stk-appbar id="appbar-top">
  <button>
    <img
      src="/assets/icons/ui/menu-24px.svg"
      class="icon drop-shadow-invert"
      alt=""
      (click)="trigger.bodyElement.classList.toggle('menu-open')"
    />
  </button>
  <a id="logo" routerLink="/">
    <img
      alt="Sing the KJV white logo"
      title="Sing the KJV logo"
      src="https://singthekjv.app/images/stklogo2017feb_improved_1400px.png"
    />
  </a>

  <img
    src="/assets/icons/ui/arrow_back_ios-24px.svg"
    class="icon drop-shadow-invert pwda-back"
    alt=""
  />
  <img
    src="/assets/icons/ui/arrow_forward_ios-24px.svg"
    class="icon drop-shadow-invert pwda-forward"
    alt=""
  />
  <img
    src="/assets/icons/ui/refresh-black-24dp.svg"
    class="icon drop-shadow-invert pwda-refresh"
    alt=""
  />

  <div class="flex-grow">
    <stk-search-input class="absolute inset-0"></stk-search-input>
  </div>
  <img
    src="/assets/icons/ui/help_outline-24px.svg"
    class="icon drop-shadow-invert"
    alt=""
  />
  <img
    src="/assets/icons/ui/settings-24px.svg"
    class="icon drop-shadow-invert"
    alt=""
  />
  <img
    src="/assets/icons/ui/apps-24px.svg"
    class="icon drop-shadow-invert"
    alt=""
  />
  <img
    src="/assets/icons/ui/supervised_user_circle-24px.svg"
    class="icon drop-shadow-invert"
    alt=""
  />
</stk-appbar>

<stk-sidebar id="sidebar-left">
  <stk-navigation-drawer></stk-navigation-drawer>
</stk-sidebar>

<stk-sidebar id="sidebar-right">
  <stk-playlist></stk-playlist>
</stk-sidebar>

<stk-appbar id="appbar-bottom">
  <stk-player></stk-player>
</stk-appbar>

<stk-hero-splash *ngIf="router.url === '/'"></stk-hero-splash>

<stk-page [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <!--<stk-page>-->
  <router-outlet #o="outlet"></router-outlet>
</stk-page>

<!--<div>Message: {{ hello$ | async | json }}</div>-->
