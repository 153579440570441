import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'stk-homepage',
  templateUrl: './homepage.view.html',
  styleUrls: ['./homepage.view.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomepageView implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
