import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Menu from '../../../json/menu.json';
import { Title } from '@angular/platform-browser';
import { ContentService } from '../../content.service';

@Component({
  selector: 'stk-path',
  templateUrl: './path.router.html',
  styleUrls: ['./path.router.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PathRouter implements AfterViewInit {
  staticPages: {} = Menu;
  currentPage: any;
  template: any;

  subtitles = {
    'song': 'Singing with accompaniment',
    'draft': 'Work in progress',
    'acappella': 'Singing without accompaniment',
    'instrumental': 'Music without singing',
  };

  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title, private content: ContentService) {
    router.events.subscribe(() => {
      this.checkRoute();
    });
  }

  ngAfterViewInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  private checkRoute() {
    const path = window.location.href.replace(window.location.origin + '/' , '').split('.');

    if (path[0] in this.staticPages) {
      this.currentPage = this.staticPages[path[0]];
    } else if (path[0] in this.content.songPages && path[1] in this.content.songPages[path[0]]) {
      this.currentPage = {
        title: this.content.songPages[path[0]]['chapter_name'] + " " + path[1].charAt(0).toUpperCase() + path[1].slice(1),
        subtitle: this.subtitles[path[1]],
        path: path.join('.'),
        chapter: this.content.songPages[path[0]],
        type: path[1]
      }
    } else {
      this.currentPage = {
        title: '404',
        path: '/404',
        content: 'sorry, 404'
      }
    }

    this.titleService.setTitle(this.currentPage['title'] + ' - Sing the KJV');
  }
}
