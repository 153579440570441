import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Menu from '../../../json/menu.json';
import { Router } from '@angular/router';

@Component({
  selector: 'stk-navigation-drawer',
  templateUrl: './navigation-drawer.component.html',
  styleUrls: ['./navigation-drawer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationDrawerComponent implements OnInit {
  menuJSON: {} = Menu;

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  reuse(path) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    return path;
  }

  originalOrder() { return 0; }
}
