import {
  trigger,
  animate,
  transition,
  style,
  query, group
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  // The '* => *' will trigger the animation to change between any two states
  transition('* => *', [
    style({ height: '*' }),
    group([
      query(':leave',
        [
          style({ opacity: 1 }),
          animate('0.15s', style({ opacity: 0 })),
        ],
        { optional: true },
      ),

      query(':enter',
        [
          style({ opacity: 0 }),
          animate('0.15s', style({ opacity: 1 })),
        ],
        { optional: true },
      ),
    ]),

  ]),
]);
