import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathRouter } from './core/path/path.router';

const routes: Routes = [
  { path: '**', component: PathRouter }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    // onSameUrlNavigation: "reload"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
