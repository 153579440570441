import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TriggerService } from '../trigger.service';

@Component({
  selector: 'stk-hero-splash',
  templateUrl: './hero-splash.component.html',
  styleUrls: ['./hero-splash.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeroSplashComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('heroContainer') heroContainer;
  @ViewChild('headlineContainer') headlineContainer;
  @ViewChild('headline') headline;

  onHomePage: boolean;
  hero;
  // orientationCheckFunds = 30;
  // currentWidth = 0;

  constructor(private trigger: TriggerService) {}

  ngOnInit(): void {
    this.onHomePage = true;
    console.log('hero splash homepage OnInit');
  }

  ngAfterViewInit(): void {
    window.requestAnimationFrame(this.onHomepageAnimate.bind(this));
  }

  ngOnDestroy(): void {
    this.onHomePage = false;
  }

  private onHomepageAnimate() {
    if (this.onHomePage) {
      const opacity =
        this.trigger.scrollContainer[this.trigger.scrollOffset] /
        (this.heroContainer.nativeElement.offsetHeight -
          this.headlineContainer.nativeElement.offsetHeight);

      // this.trigger.bodyElement.style.setProperty(
      //   '--heroScrollOpacityDesc',
      //
      // );

      this.trigger.bodyElement.style.setProperty(
        '--heroScrollOpacityAsc',
        opacity * 0.4
      );

      this.headlineContainer.nativeElement.style.opacity = 1 - opacity;

      if (1 - opacity > 0) {
        this.headline.nativeElement.style.transform =
          'translateY(' +
          this.trigger.scrollContainer[this.trigger.scrollOffset] / 2 +
          'px)';
      }

      window.requestAnimationFrame(this.onHomepageAnimate.bind(this));
    }
  }

  //
  //   	public landingResizer() {
  // 		if (this.home) {
  // 		    let headerSize = 2.6;
  // 		    let headerSizeUnit = 'vw';
  // 		    if (window.innerHeight < window.innerWidth) {
  // 		        if (window.innerHeight * 2 < window.innerWidth) {
  // 		            headerSize = 4;
  // 		            headerSizeUnit = 'vh';
  // 		        } else {
  // 		            headerSize = ((window.innerHeight / window.innerWidth) * headerSize);
  // 		            if (headerSize < 2) {
  // 		                headerSize = 2;
  // 		            }
  // 		        }
  // 		    }
  // 		    document.getElementById("hero").style.fontSize = headerSize + headerSizeUnit;
  // 		}
  // 	}
  //   public resizeCheck() {
  //     hero.style.height = window.innerHeight + 'px';
  //
  //     this.landingResizer();
  //
  //     navigationBackground = document.querySelector(".nav-background");
  // 	  let navigationHeight = navigationBackground.offsetHeight;
  //
  // 	  let fadePlanA = heroContainer.offsetHeight - headline.offsetHeight; // Faded out by the time the headline fits exactly within the hero area
  // 	  let fadePlanB = heroContainer.offsetHeight / 2; // Faded out by the time we've scrolled down 1/2 of the way.
  // 	  let fadePlan = (fadePlanA < fadePlanB) ? fadePlanA : fadePlanB;
  //
  // 	  fadePlan = fadePlan - navigationHeight - navigationHeight;
  //
  //     if (fadePlan < navigationHeight) {
  //       fadePlan = navigationHeight;
  //     }
  // }
  //
  // public orientationCheck() {
  // 	//console.log('orientationChecking... ' + window.innerWidth);
  // 	this.orientationCheckFunds = 30;
  // 	this.orientationObserver();
  // }
  //
  // public orientationObserver() {
  // 	//console.log('orientationObserving... ' + window.innerWidth);
  // 	if (this.currentWidth == window.innerWidth) {
  // 		if (this.orientationCheckFunds > 0) {
  // 			this.orientationCheckFunds--;
  // 			requestAnimationFrame(this.orientationObserver);
  // 		}
  // 	}
  // 	else {
  // 		this.currentWidth = window.innerWidth;
  // 		this.resizeCheck();
  // 		requestAnimationFrame(this.orientationObserver);
  // 	}
  //
  // }
  // public customLoop() {
  // 	if (this.home) {
  // 		let op = 1 - this.container.scrollTop / this.fadePlan;
  //     this.headlineContainer.style.opacity = op;
  //     if (op > 0) {
  //       this.headline.style.transform = 'translateY(' + this.container.scrollTop / 2 + 'px)';
  //     }
  // 		window.requestAnimationFrame(this.customLoop);
  // 	}
  // }
  //
  // function nativeLoop() {
  // 	if (this.home) {
  // 		const op = 1 - window.scrollY / this.fadePlan;
  // 	    this.headlineContainer.style.opacity = op;
  // 	    if (op > 0) {
  // 		    this.headline.style.transform = 'translateY(' + window.scrollY / 2 + 'px)';
  // 		}
  // 	    window.requestAnimationFrame(this.nativeLoop);
  // 	}
  // }
}
