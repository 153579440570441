import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '../content.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../media.service';

@Component({
  selector: 'stk-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaylistComponent implements OnInit {
  playlist: any;

  constructor(private content: ContentService, public media: MediaService) {}

  ngOnInit(): void {
    this.playlist = this.content.htmlPlaylistson['Scripture Songs'][0];

    console.log(this.playlist);
  }
}
