import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'stk-appbar',
  templateUrl: './appbar.layout.html',
  styleUrls: ['./appbar.layout.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppbarLayout implements OnInit {
  constructor(public hostReference: ElementRef) {}

  ngOnInit(): void {}
}
