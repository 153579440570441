import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OverlayscrollbarsModule } from 'overlayscrollbars-ngx';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AppbarLayout } from './core/layout/appbar/appbar.layout';
import { SearchInputComponent } from './search/search-input/search-input.component';
import { NavigationDrawerComponent } from './navigation/navigation-drawer/navigation-drawer.component';
import { SidebarComponent } from './core/layout/sidebar/sidebar.component';
import { PageLayout } from './core/layout/page/page.layout';
import { AppRoutingModule } from './app-routing.module';
import { HomepageView } from './homepage/homepage.view';
import { PathRouter } from './core/path/path.router';
import { ContentListComponent } from './content-list/content-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SongDetailComponent } from './song-detail/song-detail.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { PlayerComponent } from './player/player.component';
import { CommonModule } from '@angular/common';
import { HeroSplashComponent } from './hero-splash/hero-splash.component';

@NgModule({
  declarations: [
    AppComponent,
    AppbarLayout,
    SearchInputComponent,
    NavigationDrawerComponent,
    SidebarComponent,
    PageLayout,
    HomepageView,
    PathRouter,
    ContentListComponent,
    SongDetailComponent,
    PlaylistComponent,
    PlayerComponent,
    HeroSplashComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    OverlayscrollbarsModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
