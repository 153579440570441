import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MediaService } from '../media.service';
import { ContentService } from '../content.service';

@Component({
  selector: 'stk-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayerComponent implements AfterViewInit {
  @ViewChild('audioElement0') audioElement0;
  @ViewChild('audioElement1') audioElement1;
  @ViewChild('audioElement2') audioElement2;
  @ViewChild('seekbar') seekbar;

  constructor(
    private cdRef: ChangeDetectorRef,
    public media: MediaService,
    private content: ContentService
  ) {}

  ngAfterViewInit(): void {
    [this.audioElement0, this.audioElement1, this.audioElement2].forEach(
      (value, index) => {
        this.media.seekbar = this.seekbar.nativeElement;
        this.media.players[index]['audioElement'] = value.nativeElement;

        this.media.players[index]['audioElement'].onloadeddata = () => {
          // fragments = psalm1, song, mp3
          const fragments = this.media.players[index]['src']
            .replace('/', '')
            .split('.');

          this.media.players[index]['title'] = this.content.songPages[
            fragments[0]
          ][fragments[1]]['track_title'];
          this.media.players[index]['artist'] = this.content.songPages[
            fragments[0]
          ][fragments[1]]['artist'];
          this.media.players[index]['album'] = this.content.songPages[
            fragments[0]
          ][fragments[1]]['album'];

          this.media.players[index]['loaded'] = true;
          if (this.media.isTryingToPlay) {
            this.media.currentPlayer.audioElement.play();
          }
        };
        this.media.players[index][
          'audioElement'
        ].onplaying = this.onplaying.bind(this);
        this.media.players[index]['audioElement'].onended = this.onended.bind(
          this
        );
        this.media.players[index]['audioElement'].ontimeupdate = (event) => {
          function getReadableTime(seconds, string) {
            if (!Number.isNaN(seconds)) {
              return new Date(Math.floor(seconds) * 1000)
                .toISOString()
                .substr(11, 8)
                .replace('00:0', '')
                .replace('00:', '');
            }
            return '0:00';
          }

          if (this.media.isSeekMoving) {
            this.media.isSeekMoving = false;
          }

          this.media.players[index]['time'] = getReadableTime(
            this.media.players[index]['audioElement'].currentTime,
            'currentTime'
          );
          this.media.players[index]['duration'] = getReadableTime(
            this.media.players[index]['audioElement'].duration,
            'duration'
          );
          this.media.players[index]['percentagePlayed'] =
            (this.media.players[index]['audioElement'].currentTime /
              this.media.players[index]['audioElement'].duration) *
            100;
          this.media.players[index]['percentagePlayed'] += '%';
          if (this.media.players[index]['audioElement'].buffered.length) {
            this.media.players[index]['percentageBuffered'] =
              (this.media.players[index]['audioElement'].buffered.end(0) /
                this.media.players[index]['audioElement'].duration) *
              100;
            this.media.players[index]['percentageBuffered'] += '%';
          } else {
            this.media.players[index]['percentageBuffered'] = 0;
          }
        };
      }
    );

    this.media.initialize();

    //
    //
    // this.audioA['audioElement'] = this.audioElementA.nativeElement;
    // this.audioA['playlistElement'] = document.querySelector('[data-path="/psalm1.song.mp3"]');
    // this.audioA['src'] = 'https://singthekjv.app' + this.audioA['playlistElement'].getAttribute('data-path');
    // // console.log(this.audioA['src']);
    //
    // this.audioB['audioElement'] = this.audioElementB.nativeElement;
    //
    // this.currentAudio = this.audioA;
    // this.nextAudio = this.audioB;
    //
    // const onloadeddata = (event) => {
    //   // console.log('Audio data is loaded.');
    // };
    // const onplaying = (event) => {
    //   console.log('Audio is playing.');
    //   if (this.currentSRC !== this.currentAudio['src']) {
    //     this.currentSRC = this.currentAudio['src'];
    //     this.nextAudio['playlistElement'] = this.currentAudio['playlistElement'].nextSibling;
    //     this.nextAudio['src'] = 'https://singthekjv.app' + this.nextAudio['playlistElement'].getAttribute('data-path');
    //   }
    // };
    // const onended = (event) => {
    //   console.log('Audio is ended.');
    //   this.swapPlayers();
    //   this.currentAudio.audioElement.play();
    // }
    // const onpause = (event) => {
    //   console.log('Audio is paused.');
    // };
    //
    // this.audioA['audioElement'].onloadeddata = onloadeddata;
    // this.audioB['audioElement'].onloadeddata = onloadeddata;
    //
    // this.audioA['audioElement'].onplaying = onplaying;
    // this.audioB['audioElement'].onplaying = onplaying;
    //
    // this.audioA['audioElement'].onended = onended;
    // this.audioB['audioElement'].onended = onended;
    //
    // this.audioA['audioElement'].onpause = onpause;
    // this.audioB['audioElement'].onpause = onpause;

    this.cdRef.detectChanges();
  }
  //
  // mediaNext() {
  //   let num = ++this.nextHit;
  //   let src = this.currentAudio.src;
  //   console.log({num, src});
  //   this.currentAudio.audioElement.pause();
  //   this.swapPlayers();
  //   this.currentAudio.audioElement.play();
  // }
  //
  // swapPlayers() {
  //   this.baton = this.currentAudio;
  //   this.currentAudio = this.nextAudio;
  //   this.nextAudio = this.baton;
  // }

  private onplaying(event) {
    this.media.isTryingToPlay = false;
    this.media.isPlaying = true;

    if (this.media.lastPlayedSRC !== this.media.currentPlayer.src) {
      this.media.update();
    }
  }

  private onended(event) {
    if (this.media.isPlaying) {
      this.media.isPlaying = false;
      this.media.isTryingToPlay = true;
    }
    this.media.next();
  }
}
