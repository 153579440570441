<audio
  #audioElement0
  [src]="this.media.domain + this.media.players[0]['src']"
  preload="auto"
  controls
></audio>
<audio
  #audioElement1
  [src]="this.media.domain + this.media.players[1]['src']"
  preload="auto"
  controls
  style="opacity: 0.75;"
></audio>
<audio
  #audioElement2
  [src]="this.media.domain + this.media.players[2]['src']"
  preload="auto"
  controls
  style="opacity: 0.5;"
></audio>

<div class="flex flex-col justify-between">
  <div class="flex justify-between text-xs text-shadow">
    <span>{{ media.currentPlayer?.time }}</span>
    <div
      #seekbar
      class="flex-grow percentage-bars"
      (touchstart)="media.seekStart($event)"
      (mousedown)="media.seekStart($event)"
      (touchmove)="media.seekMove($event)"
      (touchend)="media.seekEnd()"
      [style.cursor]="media.currentPlayer?.loaded ? 'pointer' : 'wait'"
      [class.seekable]="media.currentPlayer?.loaded"
    >
      <div
        id="percentage-played"
        [style.width]="media.currentPlayer?.percentagePlayed"
        [style.transition]="
          media.isPlaying && !media.isSeekMoving
            ? 'width 0.5s, height 0.25s'
            : ''
        "
      ></div>
      <div
        id="percentage-buffered"
        [style.width]="media.currentPlayer?.percentageBuffered"
      ></div>
      <div id="percentage-background"></div>
    </div>
    <span>{{ media.currentPlayer?.duration }}</span>
  </div>
  <div class="flex justify-between items-center flex-grow">
    <div class="mt-0.5 z-10 text-shadow">
      <div class="text-base">{{ media.currentPlayer?.title }}</div>
      <div class="text-xs">
        {{ media.currentPlayer?.album }} - {{ media.currentPlayer?.artist }}
      </div>
    </div>
    <div class="mt-1 absolute inset-x-0 text-center">
      <div class="inline-flex items-center">
        <button (click)="this.media.repeat()">
          <img
            src="/assets/icons/ui/repeat-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.shuffle()">
          <img
            src="/assets/icons/ui/shuffle-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.previous()">
          <img
            src="/assets/icons/ui/skip_previous-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.rewind()">
          <img
            src="/assets/icons/ui/fast_rewind-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.playPause()">
          <img
            src="/assets/icons/ui/play_arrow-24px.svg"
            class="icon drop-shadow-invert play"
          />
        </button>
        <button (click)="this.media.fastForward()">
          <img
            src="/assets/icons/ui/fast_forward-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.next()">
          <img
            src="/assets/icons/ui/skip_next-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.playbackSpeed()">
          <img
            src="/assets/icons/ui/speed-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
        <button (click)="this.media.log()">
          <img
            src="/assets/icons/ui/snooze-24px.svg"
            class="icon drop-shadow-invert"
          />
        </button>
      </div>
    </div>
    <div class="mt-2">
      <button (click)="this.media.next()">
        <img
          src="/assets/icons/ui/library_music-24px.svg"
          class="icon drop-shadow-invert"
        />
      </button>
    </div>
  </div>
</div>

<div #seekBar></div>
