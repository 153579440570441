import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'stk-page',
  templateUrl: './page.layout.html',
  styleUrls: ['./page.layout.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageLayout implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
