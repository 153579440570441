<div class="h-screen">
  <div id="homepage-rainbow"></div>
  <header id="hero" style="font-size: var(--heroFontSize);" #heroContainer>
    <div id="centerer">
      <div id="headlineContainer" style="opacity: 1;" #headlineContainer>
        <div id="headline" style="transform: translateY(0px);" #headline>
          <h1>
            <span>Verbatim Full-chapter</span> <span>Scripture Songs</span>
            <span>King James Version</span>
          </h1>
          <h2>
            — 429 chapters — 27 books — 8634 verses —<br />— 34 hours, 48
            minutes —
          </h2>
        </div>
      </div>
    </div>
    <div id="bible-wrapper">
      <div id="blacker"></div>
      <img
        id="bible-image"
        src="https://singthekjv.app/images/biblelight.jpg"
        alt
      />
    </div>
  </header>
</div>
