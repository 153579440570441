<div [innerHTML]="table"></div>

<!--<table>-->
<!--  <thead>-->
<!--  <tr>-->
<!--    <th scope="col" *ngFor="let column of columnDefs">-->
<!--      {{ column }}-->
<!--    </th>-->
<!--  </tr>-->
<!--  </thead>-->
<!--  <tbody>-->
<!--  <tr *ngFor="let item of items">-->
<!--    -->
<!--    <td>-->
<!--      {{ item['id'] }}-->
<!--    </td>-->
<!--    <td>-->
<!--      {{ item['chapter_name'] }}-->
<!--    </td>-->
<!--    <td>-->
<!--      {{ item['duration'] }}-->
<!--    </td>-->
<!--    <td>-->
<!--      {{ item['verse_count'] }}-->
<!--    </td>-->
<!--  </tr>-->
<!--  </tbody>-->
<!--</table>-->


<!--<table>-->
<!--  <caption>A summary of the UK's most famous punk bands</caption>-->
<!--  <thead>-->
<!--  <tr>-->
<!--    <th scope="col">Band</th>-->
<!--    <th scope="col">Year formed</th>-->
<!--    <th scope="col">No. of Albums</th>-->
<!--    <th scope="col">Most famous song</th>-->
<!--  </tr>-->
<!--  </thead>-->
<!--  <tbody>-->
<!--  <tr>-->
<!--    <th scope="row">Buzzcocks</th>-->
<!--    <td>1976</td>-->
<!--    <td>9</td>-->
<!--    <td>Ever fallen in love (with someone you shouldn't've)</td>-->
<!--  </tr>-->
<!--  <tr>-->
<!--    <th scope="row">The Clash</th>-->
<!--    <td>1976</td>-->
<!--    <td>6</td>-->
<!--    <td>London Calling</td>-->
<!--  </tr>-->

<!--  ... some rows removed for brevity-->

<!--  <tr>-->
<!--    <th scope="row">The Stranglers</th>-->
<!--    <td>1974</td>-->
<!--    <td>17</td>-->
<!--    <td>No More Heroes</td>-->
<!--  </tr>-->
<!--  </tbody>-->
<!--  <tfoot>-->
<!--  <tr>-->
<!--    <th scope="row" colspan="2">Total albums</th>-->
<!--    <td colspan="2">77</td>-->
<!--  </tr>-->
<!--  </tfoot>-->
<!--</table>-->
