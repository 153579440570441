import {
  Component,
  HostListener,
  NgZone,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import OverlayScrollbars from 'overlayscrollbars';
import SmoothScroll from 'smoothscroll-for-websites';
import { Router, RouterOutlet } from '@angular/router';
import { fadeAnimation } from './animation';
import { TriggerService } from './trigger.service';
import { MediaService } from './media.service';

// import { HttpClient } from '@angular/common/http';
// import { Message } from '@singthekjv/api-interfaces';

@Component({
  selector: 'stk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  // @HostListener('window:mousemove', ['$event']) mouseMove(event) {
  //   this.media.seekMove(event);
  //   console.log('huh');
  // }

  @HostListener('window:mouseup', ['$event']) mouseUp() {
    this.media.seekEnd();
  }

  @HostListener('window:keydown', ['$event']) keyDown(event: KeyboardEvent) {
    if (this.trigger.userIsTyping !== true) {
      this.trigger.keyDown(event);
    }
  }

  @HostListener('window:keyup', ['$event']) keyUp(event: KeyboardEvent) {
    if (this.trigger.userIsTyping !== true) {
      this.trigger.keyUp(event);
    }
  }

  // @HostListener('body:mousewheel', ['$event']) onMousewheel(event) {
  //   if (this.trigger.altKeyDown) {
  //     this.trigger.mousewheel(event.deltaY < 0);
  //   }
  // }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  constructor(
    public router: Router,
    private ngZone: NgZone,
    public trigger: TriggerService,
    private media: MediaService
  ) {
    console.log(router);
  }

  navigate(route: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.navigate([route]);
  }

  ngOnInit(): void {
    window['angularRouter'] = {
      zone: this.ngZone,
      navigate: (route) => this.navigate(route),
    };

    OverlayScrollbars(document.body, {
      className: 'os-theme-light',
      nativeScrollbarsOverlaid: {
        initialize: false,
      },
      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 800,
        dragScrolling: true,
        clickScrolling: true,
        touchSupport: true,
      },
      callbacks: {
        onInitialized: () => {
          this.trigger.scrollContainer = document.querySelector('.os-viewport');
          this.trigger.scrollOffset = 'scrollTop';
        },
        // onInitializationWithdrawn: () => {
        //   this.trigger.scrollContainer = window;
        //   this.trigger.scrollOffset = 'scrollY';
        // },
      },
    });

    SmoothScroll({
      // Scrolling Core
      frameRate: 60, // [Hz]
      animationTime: 300, // 400 [ms]
      stepSize: 100, // 100 [px]

      // Acceleration
      accelerationDelta: 50, // 50
      accelerationMax: 3, // 3

      // Keyboard Settings
      keyboardSupport: true, // true,  option
      arrowScroll: 50, // 50, [px]

      // Pulse (less tweakable)
      // ratio of "tail" to "acceleration"
      pulseAlgorithm: true,
      pulseScale: 4,
      pulseNormalize: 1,

      // Other
      touchpadSupport: false, // ignore touchpad by default
      fixedBackground: true,
      excluded: '',
    });

    this.trigger.watch();
  }

  // hello$ = this.http.get<Message>('/api/hello');
  // constructor(private http: HttpClient) {}
}
