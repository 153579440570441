import { Injectable } from '@angular/core';
import Repo from '../json/repository.json';
import htmlTables from '../json/htmlTables.json';
import htmlPlaylists from '../json/htmlPlaylists.json';
import SongPages from '../json/song_pages.json';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  repoJSON: {} = Repo;
  htmlTableJson: {} = htmlTables;
  htmlPlaylistson: {} = htmlPlaylists;
  songPages: {} = SongPages;

  constructor() { }
}
