import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContentService } from '../content.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'stk-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentListComponent implements OnInit {
  items: any = [];

  columnDefs = ["Ch #", "Chapter", "Duration", "Verses"];
  columnDefinitions = ["id", "track_title", "duration", "verse_count"];

  table: any = ``;

  constructor(private content: ContentService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    console.log('working so hard');
    const currentPath = window.location.pathname.substring(1);
    let tableName = currentPath;
    switch (currentPath) {
      case 'spiritual-songs':
        tableName += '-album-asc';
        break;
      case 'scripture-songs':
        tableName += '-id-asc';
        break;
    }
    console.log({tableName})
    this.table = this.sanitizer.bypassSecurityTrustHtml(this.content.htmlTableJson[tableName]);

    // if (currentPath === 'scripture-songs') {
    //   for (const key in this.content.repoJSON) {
    //     // tslint:disable-next-line:radix
    //     if (parseInt(key) < 1500) {
    //       if (this.content.repoJSON[key]['song.mp3']) {
    //         this.items.push(this.content.repoJSON[key]['song.mp3']);
    //       } else if (this.content.repoJSON[key]['draft.mp3']) {
    //         this.items.push(this.content.repoJSON[key]['draft.mp3']);
    //       }
    //     }
    //   }
    // }
  }
}
