import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import BookOfferings from '../../json/book_offerings.json';
import BookNames from '../../json/book-id-to-book-name.json';
import { Router } from '@angular/router';
import { ContentService } from '../content.service';

@Component({
  selector: 'stk-song-detail',
  templateUrl: './song-detail.component.html',
  styleUrls: ['./song-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SongDetailComponent implements OnInit {
  @Input() chapter: any;
  @Input() type: any;

  bookOfferings: {} = BookOfferings;
  bookNames: {} = BookNames;
  otherChapters: any = false;

  songInfo: {} = {
    track_title: 'Track',
    album: 'Album',
    artist: 'Artist',
    duration: 'Duration',
    actions: 'Actions',
  };

  constructor(public router: Router, public content: ContentService) {}

  ngOnInit(): void {
    const bookShort =
      '/' +
      this.bookNames[this.chapter.book_id]
        .replace(' ', '')
        .replace('salms', 'salm')
        .toLowerCase();

    console.log(this.chapter);

    for (const ch in this.bookOfferings[this.chapter.book_id]) {
      const types = this.bookOfferings[this.chapter.book_id][ch];
      if (ch !== this.chapter.ch) {
        for (const type of types) {
          if (
            type === this.type ||
            (['song', 'draft'].includes(type) &&
              ['song', 'draft'].includes(this.type))
          ) {
            if (!this.otherChapters) {
              this.otherChapters = {};
              this.otherChapters[this.chapter.ch] = '';
            }
            this.otherChapters[ch] = bookShort + ch + '.' + type;
            break;
          }
        }
      }
    }

    if (this.type === 'draft') {
      this.type = 'song';
    }
  }

  navigate(path: string) {
    this.router.navigate([path]);
  }

  originalOrder() {
    return 0;
  }
}
