import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  players = {
    0: {
      src: '',
      audioElement: null,
      playlistElement: null,
      time: 0,
      duration: '',
      loaded: false,
      title: '',
      artist: '',
      album: '',
    },
    1: {
      src: '',
      audioElement: null,
      playlistElement: null,
      time: 0,
      duration: '',
      loaded: false,
      title: '',
      artist: '',
      album: '',
    },
    2: {
      src: '',
      audioElement: null,
      playlistElement: null,
      time: 0,
      duration: '',
      loaded: false,
      title: '',
      artist: '',
      album: '',
    },
  };

  previousPlayer;
  currentPlayer;
  nextPlayer;
  history = [];
  seekbar;

  isLoaded = false;
  isSeeking = false;
  isSeekMoving = false;
  isPlaying = false;
  isTryingToPlay = false;
  lastPlayedSRC = null;
  percentagePlayed: any;

  domain = 'https://singthekjv.app';

  constructor() {}

  initialize() {
    this.players[0]['playlistElement'] = document.querySelector(
      '[data-path="/psalm1.song.mp3"]'
    );
    this.players[0]['src'] = this.players[0]['playlistElement'].getAttribute(
      'data-path'
    );

    this.currentPlayer = this.players[0];
    this.nextPlayer = this.players[1];
    this.previousPlayer = this.players[2];
    // Math.floor(this.currentTime) + ' / ' + Math.floor(this.duration)
    // for (const key in this.players) {
    //   console.log(key);
    //   console.log(this.players[key]);
    //   if (this.players.hasOwnProperty(key)) {
    //     console.log('key is good');
    //     this.players[key]['audioElement'].timeupdate = (event) => {
    //       console.log('updating time .. ' + Math.floor(this.players[key]['audioElement'].currentTime));
    //       this.players[key]['time'] = Math.floor(this.players[key]['audioElement'].currentTime);
    //     };
    //   }
    // }
  }

  playPause() {
    if (this.isPlaying) {
      this.currentPlayer.audioElement.pause();
      this.isPlaying = false;
    } else {
      this.currentPlayer.audioElement.play();
    }
  }

  next(allowLoadStart = false) {
    console.log('next');
    this.updateHistory();
    this.currentPlayer.audioElement.pause();
    this.cyclePlayersForward();
    if (!allowLoadStart) {
      this.startFresh();
    }
  }

  previous() {
    this.currentPlayer.audioElement.pause();
    this.cyclePlayersBackward();
    this.startFresh();
  }

  startFresh() {
    this.currentPlayer.audioElement.currentTime = 0;
    if (this.isPlaying || this.isTryingToPlay) {
      this.currentPlayer.audioElement.play();
    }
  }

  private cyclePlayersForward() {
    const baton = this.previousPlayer;

    this.previousPlayer = this.currentPlayer;
    this.currentPlayer = this.nextPlayer;
    this.nextPlayer = baton;
  }

  private cyclePlayersBackward() {
    const baton = this.nextPlayer;

    this.nextPlayer = this.currentPlayer;
    this.currentPlayer = this.previousPlayer;
    this.previousPlayer = baton;
  }

  rewind() {
    this.currentPlayer.audioElement.currentTime -= 10;
  }

  fastForward() {
    this.currentPlayer.audioElement.currentTime += 10;
  }

  setCurrentTrack(targetTrackElement) {
    const src = targetTrackElement.getAttribute('data-path');
    this.isTryingToPlay = true;

    if (this.currentPlayer['src'] !== src) {
      if (this.nextPlayer['src'] !== src) {
        this.isPlaying = false;
        this.nextPlayer['playlistElement'] = targetTrackElement;
        this.nextPlayer['src'] = src;
        this.next(true);
      } else {
        this.next();
      }
    } else {
      this.startFresh();
    }
  }

  update() {
    const nowPlayingElements = document.querySelectorAll('.media-now-playing');

    if (nowPlayingElements[0]) {
      nowPlayingElements.forEach((element) => {
        element.classList.remove('media-now-playing');
      });
    }

    this.currentPlayer.playlistElement.classList.add('media-now-playing');

    this.lastPlayedSRC = this.currentPlayer.src;

    this.nextPlayer['playlistElement'] = this.currentPlayer[
      'playlistElement'
    ].nextSibling;
    this.nextPlayer['src'] = this.nextPlayer['playlistElement'].getAttribute(
      'data-path'
    );

    // if (!this.previousPlayer['playlistElement']) {
    //   console.log('no previous player item setup, so, setting as previous on list');
    this.previousPlayer['playlistElement'] = this.currentPlayer[
      'playlistElement'
    ].previousSibling;
    this.previousPlayer['src'] = this.previousPlayer[
      'playlistElement'
    ].getAttribute('data-path');
    // }
  }

  private updateHistory() {
    if (this.lastPlayedSRC) {
      this.history.push(this.lastPlayedSRC);
      if (this.history.length > 100) {
        this.history = this.history.shift();
      }
    }
  }

  seekStart(event) {
    event.preventDefault();
    this.isSeeking = true;
    this.isSeekMoving = true;
    this.seekMove(event);
  }

  seekEnd() {
    this.isSeeking = false;
  }

  seekMove(event) {
    if (this.isSeeking) {
      this.isSeekMoving = true;
      const pageX = event.pageX || event.touches[0].pageX;
      const offset = this.seekbar.offsetLeft;
      this.currentPlayer.audioElement.currentTime =
        ((pageX - offset) / this.seekbar.clientWidth) *
        this.currentPlayer.audioElement.duration;
    }
  }

  repeat() {}

  shuffle() {}

  playbackSpeed() {}

  sleepTimer() {}

  log() {
    console.log(this.isPlaying);
  }
}
