<stk-page>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
  <p>homepage works!</p>
</stk-page>
